<template>
  <div class="greybackgroundpayscreen"></div>
</template>

<script>
import ApiService from "@/core/services/api.service";

export default {
  name: "paymentsuccess",
  components: {},
  data() {
    return {
      showError: false,
    };
  },
  mounted() {
    var id = this.$route.params.id;
    ApiService.post("apps/todo/task/paysuccess/" + id).then((res) => {
      if (res.data.success) {
        this.$router.push({
          name: "dashboardreview",
          params: { id: res.data.id },
          query: { downloadflag: "Yes" },
        });
        // this.$router.push({name: 'dashboard'});
      } else {
        this.showError = true;
      }
    });
  },
  methods: {},
};
</script>

<style>
.Pay-Completed-Rectangle {
  width: 107px;
  height: 30px;
  padding: 7px 25px 8px;
  opacity: 0.2;
  border-radius: 17px;
  background-color: #33b985;
}
.Pay-Completed-Rectangle span {
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.15;
  letter-spacing: normal;
  text-align: left;
  color: black;
  opacity: 1 !important;
}

.pay-bottom-line-hightlight {
  border-bottom: solid 1px #e9eef3;
}

.pay-displayText-Title {
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #3f6f86;
}
.pay-displayText {
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #3f6f86;
}

.paypushTextright {
  text-align: right;
}

.dashboardpay {
  padding: 0;
}

.dashboardpay .container-fluid {
  padding: 0 0 0 25px;
}

.pay-rowinbox {
  margin: 25px 0 45px;
  padding: 23px 30px 23px 28px;
  border-radius: 8px;
  box-shadow: 0 1px 8px 0 rgba(233, 238, 243, 0.71);
  border: solid 1px rgba(232, 232, 232, 0.8);
  background-color: white;
}

.greybackgroundpayscreen {
  min-height: 100%;
}

.payButton {
  width: 500px;
  height: 64px;
  margin: 60px 0 0;
  border-radius: 8px;
  background-color: #33b985 !important;
  color: white !important;
  font-size: 15px;
  font-weight: 500;
}

#carderrors,
#nameerrors {
  color: #cc0000;
  font-weight: 700;
}
</style>
